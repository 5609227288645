<template>
  <div class="app">
    <div class="common-content">
       <el-row :gutter="0"><!--v-if="isshow" @click.native="changer()" -->
         <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOutLeft" v-show="isshow" >
        <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6"  v-if="isshow" class="sidebar animated">
          <div class="grid-content bg-purple" @click="changer($event)">
            <img src="../../assets/images/ceo-pic.png" alt>
            <h4>{{info.nick_name}}</h4>
            <p>
              账号：
              <span>{{info.mobile}}</span>
            </p>
            <el-menu >
              <router-link :to='{name:"UserHome"}' active-class="active">
                <el-menu-item index="2" >
                  <!-- <i class="el-icon-menu"></i> -->
                  <svg class="icon" aria-hidden="true" >
                  <use xlink:href="#icon-ziyuan17"></use>
                  </svg>
                  <span slot="title">我的主页</span>
                </el-menu-item>
              </router-link>
              <router-link :to='{name:"Ipoints"}' active-class="active">
                <el-menu-item index="3">
                  <!-- <i class="el-icon-setting"></i> -->
                  <svg class="icon" aria-hidden="true" >
                  <use xlink:href="#icon-ziyuan16"></use>
                  </svg>
                  <span slot="title">我的积分</span>
                </el-menu-item>
              </router-link>
              <router-link :to='{name:"Transactions"}' active-class="active">
                <el-menu-item index="4">
                  <!-- <i class="el-icon-menu"></i> -->
                  <svg class="icon" aria-hidden="true" >
                  <use xlink:href="#icon-ziyuan15"></use>
                  </svg>
                  <span slot="title">交易记录</span>
                </el-menu-item>
              </router-link>
              <router-link :to='{name:"UserInfo"}' active-class="active">
                <el-menu-item inex="1">
                  <!-- <i class="el-icon-menu"></i> -->
                  <svg class="icon" aria-hidden="true" >
                    <use xlink:href="#icon-ziyuan14"></use>
                  </svg>
                  <span slot="title">我的信息</span>
                </el-menu-item>
              </router-link>
              <router-link :to='{name:"Updatepwd"}' active-class="active">
                <el-menu-item inex="5">
                  <!-- <i class="el-icon-menu"></i> -->
                  <svg class="icon" aria-hidden="true" >
                    <use xlink:href="#icon-ziyuan14"></use>
                  </svg>
                  <span slot="title">修改密码</span>
                </el-menu-item>
              </router-link>
              <router-link :to='{name:"Tenants"}' active-class="active" v-if="info.is_staff===0">
                <el-menu-item index="6">
                    <!-- <i class="el-icon-location"></i> -->
                    <svg class="icon" aria-hidden="true" >
                    <use xlink:href="#icon-ziyuan12"></use>
                    </svg>
                    <span v-if='info.store!==""&&store.status<=0 || !info.store'>申请入驻</span>
                    <span v-if='info.store!==""&&store.status>0'>入驻信息</span>
                </el-menu-item>
              </router-link>
                <!-- <el-menu-item-group  v-if='info.store!==""&&store.status>0'> -->
              <div v-if='info.store!==""&&store.status>0'>
              <router-link :to='{name:"Viewrecord"}' active-class="active">
                <el-menu-item index="7"  >
                  <svg class="icon" aria-hidden="true" >
                   <use xlink:href="#icon-ziyuan13"></use>
                  </svg>
                  <span>观看记录</span>
                </el-menu-item>
              </router-link>
              <router-link :to='{name:"Eviewrecord"}' active-class="active">
                <el-menu-item index="8"  class="el-menu-item-read">
                  <svg class="icon" aria-hidden="true" >
                   <use xlink:href="#icon-ziyuan12"></use>
                  </svg>
                  <span>员工观看记录</span>
                </el-menu-item>
              </router-link>
              <router-link :to='{name:"Department"}' active-class="active">
                <el-menu-item index="9"  >
                  <svg class="icon" aria-hidden="true" >
                   <use xlink:href="#icon-ziyuan13"></use>
                  </svg>
                  <span>部门管理</span>
                </el-menu-item>
              </router-link>
              <router-link :to='{name:"Employees"}' active-class="active">
                <el-menu-item index="10" >
                  <svg class="icon" aria-hidden="true" >
                   <use xlink:href="#icon-ziyuan18"></use>
                  </svg>
                  <span>员工管理</span>
                </el-menu-item>
              </router-link>
                <!-- </el-menu-item-group> -->
            </div>
            </el-menu>

          </div>
        </el-col>
         </transition>
        <el-col :xs="16" :sm="18" :md="18" :lg="18" :xl="18" class="personal-content">
          <div class="grid-content bg-purple-light">
           <i class="el-icon-menu menu" @click="showBar()" v-show="!isshow"></i>
            <router-view></router-view>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      info: {},
      store: {},
      isshow: false,
      clientWidth: 0
    }
  },
  methods: {

    changer (e) {
      let currentTag = e.target.closest('li')
      if (currentTag) {
        for (let ind in currentTag.children) {
          let list = currentTag.children[ind]
          if (list.nodeName === 'UL') {
            this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
            if (this.clientWidth < 700) {
              this.isshow = true
              break
            }
          } else {
            this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
            if (this.clientWidth < 700) {
              this.isshow = false
            }
          }
        }
      }
      // else {
      //   this.isshow = false
      // }
    },
    getInfo () {
      this.$store.dispatch('getInfo').then(res => {
        // console.log(res)
        this.info = res.info
        // this.$store.getters('getUserId', res.info.id)
        if (res.info.store) {
          this.store = res.info.store
          // console.log('store:', res.info)
        }
      })
    },
    // 侧边栏
    showBar () {
      this.isshow = !this.isshow
    }
  },
  mounted () {
    this.getInfo()
    this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
    if (this.clientWidth > 700) {
      this.isshow = true
    }
  }
}
</script>
<style lang="less" scoped>
.app {
  background: @global-personal-background;
  padding: 50px 0;
  .icon{width:20px;height:20px;fill:#555;margin-right:.5rem }
  .common-content {
    box-shadow: 2px 5px 20px @global-gray;
    .bg-purple {
      background: @global-personal-background;
      padding-top: 20px;
      text-align: center;
      img {
        width: 80px;
        height: 80px;
      }
      .el-menu {
        border-right: none;
        margin-top: 20px;
        background-color: @global-personal-background;
        overflow: hidden;
        .el-menu-item-read{
          padding-left:50px !important
        }
        .active{
              display: -webkit-box;
              border-bottom:none;
          .el-menu-item{
            width:100%;
            color:@global-head-active-color;
            background: @global-white;
            .icon{
              fill:@global-head-active-color
            }
          }
          .el-menu-item-group{
              .el-menu-item{
                .icon{
                  fill:#555 !important
                }
              }
            }
          .el-submenu{
            width:100%;
            background: @global-white;

            .el-submenu__title{
              .icon{
                fill:@global-head-active-color;
              }
              span{
               color:@global-head-active-color;
              }
            }
            .el-menu-item-group{
              .el-menu-item{
                color:#555
              }
            }
          }
        }
        .is-active {
          color: #555;
        }
      }
    }
    .bg-purple-light {
      background: @global-white;
      min-height:735px
    }
  }
}
@media screen and (max-width: 700px) {
    .sidebar{
    // display: none
    width:50%;
    position: fixed;
    z-index: 999;
    height:100%;
    top:0;
    .bg-purple{
      height:100%;
      img{
        width:50px !important;
        height:50px !important
      }
      h4,p{
        margin:10px 0
      }
    }
  }

  .personal-content{
    width: 100%
  }
    .grid-content{
    text-align: left;
}
  .menu{
    font-size: 2rem ;
    margin-left: 0.3rem;
    margin-top:1rem;

  }

}
</style>
